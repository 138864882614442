import { checkBrowserSupport, hasBrowserSkusService, isBrave, isLinux } from "./utils";
import productData from "./products.json";

const productPropMap = {
  "product_id": "id",
  "trial_days": "trialDays",
};

type CoreProductProps = {
  id?: string;
  name: string;
  price: number;
  trialDays?: number;
};

type ExtraProductProps = {
  baseName: string;
  displayName: string;
  description: string;
  link: string;
  features: Array<string>;
  hasFreeVersion: boolean;
  freeFeatures?: Array<string>;
  actionPhrase: string;
  usesNativeSDK: boolean;
  weight?: number;
  browserSupport?: { isSupported: boolean; message?: string };
};

export type Product = CoreProductProps & ExtraProductProps;

export type Products = Array<Product>;

const extraProductInfo: Record<string, ExtraProductProps> = {
  talk: {
    baseName: "Talk",
    displayName: "Talk Premium",
    description:
      "Brave Talk brings unlimited, private video calls for small groups, right to your browser. With Brave Talk Premium you can host calls with hundreds of people, use moderator controls, and more.",
    link: "https://brave.com/talk/",
    features: ["Call recordings", "Participant muting & passcodes", "Groupwatch for YouTube"],
    hasFreeVersion: true,
    freeFeatures: ["(1-1 calls)"],
    actionPhrase: "start a call",
    usesNativeSDK: false,
    browserSupport: checkBrowserSupport(),
  },
  search: {
    baseName: "Search",
    displayName: "Search Premium",
    description:
      "Brave Search is the world’s fastest growing private search engine. It doesn't profile you, and it's not beholden to Big Tech. A premium subscription supports the Brave Search mission, and brings ad-free results.",
    link: "#",
    features: [
      "Support private, independent search",
      "See search results ad-free",
      "Get a cleaner view on all results pages",
    ],
    hasFreeVersion: true,
    actionPhrase: "search the web",
    usesNativeSDK: false,
    browserSupport: checkBrowserSupport(),
  },
  vpn: {
    baseName: "Firewall + VPN",
    displayName: "Firewall + VPN",
    description:
      "Traveling? On public Wi-Fi? Don't trust your ISP? It's time for the whole-device protection of Brave Firewall + VPN.",
    link: "#",
    features: [
      "Block trackers on any app",
      "Protect up to 5 devices",
      "Unlimited with speeds up to 500mbps",
      "Desktop, Android, & iOS",
    ],
    hasFreeVersion: false,
    actionPhrase: "subscribe",
    usesNativeSDK: true,
    weight: 2,
    browserSupport: checkBrowserSupport(() => {
      if (!isBrave()) {
        return "Brave VPN is available exclusively in the Brave browser. Download Brave to encrypt everything you do online with Brave VPN.";
      }

      if (isLinux()) {
        return "It looks like you're trying to use Brave VPN on a Linux device. Brave VPN is currently supported on Android, iOS, macOS, and Windows devices (support for Linux is coming soon).";
      }

      if (!hasBrowserSkusService()) {
        return "It looks like you're using a version of Brave that's not compatible with Brave VPN. Please update to the latest version of Brave to use Brave VPN.";
      }
    }),
  },
  leo: {
    baseName: "Leo AI",
    displayName: "Leo AI Premium",
    description:
      "Leo is an AI-powered intelligent assistant, built right into the browser. Leo can answer questions, help accomplish tasks, and more. Leo Premium uses more models to handle more requests each day, and lets you try new features first.",
    link: "#",
    features: ["Access to industry-leading LLMs", "Higher rate limits", "Early access to new features"],
    hasFreeVersion: true,
    actionPhrase: "subscribe",
    usesNativeSDK: true,
    weight: 1,
    browserSupport: checkBrowserSupport(() => {
      if (!isBrave()) {
        return "Leo AI is available exclusively in the Brave browser. Download Brave to use the private, accurate Leo AI.";
      }

      if (!hasBrowserSkusService()) {
        return "It looks like you're using a version of Brave that's not compatible with Leo AI. Please update to the latest version of Brave to use Leo AI.";
      }
    }),
  },
};

export const products = productData.map((p) => {
  let product: Product = {} as Product;

  // Ensure prop names from server are mapped to those used by frontend
  for (const key in p) {
    product[productPropMap[key] || key] = p[key];
  }

  // Merge core product props with extra info needed for display
  product = {
    ...product,
    ...extraProductInfo[p.name],
  };

  return product;
});

export const getIdByName = (name: string): string => {
  const product = products.find((product: Product) => {
    return name === product.name;
  }) as Product;
  return product ? product.id : null;
};

export const getProductByName = (name: string): Product => {
  return products.find((product: Product) => {
    return name === product.name;
  }) as Product;
};

export default products;
